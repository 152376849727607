import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './i18n'
import { HelmetProvider } from 'react-helmet-async';

import reportWebVitals from './reportWebVitals';

import './styles/globals.css';
import { lazy } from 'react';
import { LoadingProvider } from './providers/loading-provider';
import MetaTags from './components/meta-tags';

const NotFoundPage = lazy(() => import('./pages/NotFoundPage').then(({ NotFoundPage }) => ({ default: NotFoundPage })),);
const HomePage = lazy(() => import('./pages/home-page').then(({ HomePage }) => ({ default: HomePage })));

// Initialize React Ga with your tracking ID
ReactGA.initialize('G-LC7KKF93J8');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Router>
    <LoadingProvider />
    <HelmetProvider>
      <MetaTags />
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path='*' Component={NotFoundPage} />
      </Routes>
    </HelmetProvider>
  </Router>
);

reportWebVitals();
